import React from 'react';
import './Footer.css';

const Footer: React.FC = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <p>© {new Date().getFullYear()} Edwin Cloud. All rights reserved.</p>
                <p>edwin@edwincloud.com | <a href="https://www.linkedin.com/in/edwinsch/" target="_blank" rel="noopener noreferrer" className="footer-link">LinkedIn</a></p>
            </div>
        </footer>
    );
};

export default Footer;

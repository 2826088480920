import React from 'react';
import InitPageV1 from './init-pages/InitPageV1';
import Header from './PageShellComponents/Header';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import KnowMyWork from './content-components/KnowMyWork';

const App: React.FC = () => {
  return (
    <Router>
      <div className="app-container">
        <Routes>
        
        <Route path="/thermofisher" element={<KnowMyWork />} />
          <Route path="/my-customers" element={<KnowMyWork />} />
          <Route path="/know-my-work" element={<KnowMyWork />} />
          <Route path="/" element={<InitPageV1 />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

import React from 'react';
import './Header.css'; // Ensure correct path

const Header: React.FC = () => {
    const email = "edwin@edwincloud.com";

    const copyToClipboard = () => {
        navigator.clipboard.writeText(email).then(() => {
            alert('Email address copied to clipboard!');
        }, (err) => {
            console.error('Could not copy text: ', err);
        });
    };

    return (
        <header className="header-navbar">
            <div className="header-container">
                <a href="/" className="navbar-brand">edwin-cloud</a>
                <nav className="navbar">
                    <ul>
                        <li>
                            <a href="/know-my-work" className="nav-link">my_resume</a>
                        </li>
                        <li>
                            <a href="mailto:edwin@edwincloud.com" className="nav-link">contact_me</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Header;

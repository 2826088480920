export class KnowMyWorkContent {
  title: string;
  description: string;
  descriptionList?: string[]; // Optional array of strings
  details1: string;
  details2: string;
  imageUrl: string;
  footerText: string;

  constructor(title: string, description: string, details1: string, details2: string, imageUrl: string, footerText: string = '', descriptionList?: string[]) {
    this.title = title;
    this.description = description;
    this.details1 = details1;
    this.details2 = details2;
    this.imageUrl = imageUrl;
    this.footerText = footerText;
    this.descriptionList = descriptionList;
  }

  getTitle(): string {
    return this.title;
  }

  getDescription(): string {
    return this.description;
  }

  getDescriptionList(): string[] | undefined {
    return this.descriptionList;
  }

  getDetail1(): string {
    return this.details1;
  }

  getDetail2(): string {
    return this.details2;
  }

  getImageUrl(): string {
    return this.imageUrl;
  }

  getFooterText(): string {
    return this.footerText;
  }
}

class GoogleCalendarRequest {
    title: string;
    details: string;
    location: string;
    startTime: Date;
    endTime: Date;
    emailAddress: string;

    constructor(
        title: string,
        details: string,
        location: string,
        startTime: Date,
        endTime: Date,
        emailAddress: string
    ) {
        this.title = title;
        this.details = details;
        this.location = location;
        this.startTime = startTime;
        this.endTime = endTime;
        this.emailAddress = emailAddress;
    }

    private formatDateTime(date: Date): string {
        // Converts date to YYYYMMDDTHHMMSSZ format
        return date.toISOString().replace(/-|:|\.\d\d\d/g, '');
    }

    generateLink(): string {
        const startDate = this.formatDateTime(this.startTime);
        const endDate = this.formatDateTime(this.endTime);
        const encodedTitle = encodeURIComponent(this.title);
        const encodedDetails = encodeURIComponent(this.details);
        const encodedLocation = encodeURIComponent(this.location);

        return `https://calendar.google.com/calendar/u/0/r/eventedit?dates=${startDate}/${endDate}&text=${encodedTitle}&details=${encodedDetails}&location=${encodedLocation}&add=${this.emailAddress}`;
    }
}

export default GoogleCalendarRequest;

import React from 'react';
import './TypesOfSoftware.css';
import { TypesOfSoftwareContent } from '../models/TypesOfSoftwareContent';

const contentData: TypesOfSoftwareContent[] = [
    new TypesOfSoftwareContent("Transforming Ideas into Launch-Ready Products"
        , "Turning your idea into a launch-ready product is my specialty. Instead of offering run-of-the-mill 'MVP development services,' I provide a straightforward path to bring your vision to market. My approach cuts through the noise, focusing directly on building a product with the essential features your users need - efficient, relevant, and ready to make an impact. I'm here to accelerate your journey from concept to market feedback, setting the stage for your business to thrive. Let's make your idea a reality, swiftly and effectively."
        , "/image/mvp.webp", ""),

    new TypesOfSoftwareContent("Empower Your Applications with ChatGPT API Integration"
        , "Elevate your application with the integration of ChatGPT, enhancing user interactions, automating responses, and providing round-the-clock assistance. By embedding ChatGPT into your platform, you can leverage cutting-edge AI for seamless integration, putting you at the forefront of innovation and customer satisfaction. This transformation offers users an unmatched experience, keeping you ahead of the curve in service excellence."
        , "/image/ai-api.jpeg", ""),

    new TypesOfSoftwareContent("Your App on Every Device: iOS & Android Development"
        , "Transform your mobile app vision into reality on every platform with my dedicated iOS and Android development expertise. I specialize in crafting apps that not only engage users but also deliver a seamless experience across both iOS and Android devices. By focusing on user-friendly design and cross-platform performance, I ensure your app stands out in the mobile market. Partner with me, and launch with the confidence that your app is universally accessible and poised for success."
        , "/image/ios-android-app.jpeg", ""),

    new TypesOfSoftwareContent("Expand Your Reach with Microsoft Windows Desktop App Development"
        , "Unlock the full potential of the Microsoft Windows Desktop App Store market with my specialized development service. I focus on creating sleek, high-performance apps tailored for the Windows ecosystem. By ensuring your product is not just functional but stands out, I aim to help you tap into and capitalize on the vast opportunities within the Windows platform. Let's extend your reach together, making your app accessible and appealing to a wider audience."
        , "/image/windows-app.jpeg", "")
];

const TypesOfSoftware: React.FC = () => {
    return (
        <div className="typesOfSoftware-container">
            {contentData.map((content, index) => (
                <div key={index} className='typesOfSoftware-contentItem'>
                    <h2>{content.getTitle()}</h2>
                    <div className="contentBody"> {/* New wrapper div */}
                        <img
                            src={content.getImageUrl()}
                            alt={content.getTitle()}
                            className="softwareImage"
                        />
                        <p className='bigger-plus-1-text'>{content.getDescription()}</p>
                    </div>
                    <p>{content.getFooterText()}</p>
                </div>
            ))}
        </div>
    );
};


export default TypesOfSoftware;

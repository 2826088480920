import React from 'react';
import './InitPageV1.css';
import TypesOfSoftware from './TypesOfSoftware';
import GoogleCalendarRequest from '../models/GoogleCalendarRequest';
import Header from '../PageShellComponents/Header';
import Footer from '../PageShellComponents/Footer';

const InitPageV1: React.FC = () => {

    const calendarRequest = new GoogleCalendarRequest(
        "Video Call with Edwin",
        "Write a simple, short description of what you need.",
        "Online",
        new Date(Date.now() + 86400000),
        new Date(Date.now() + 90000000),
        "edwin@edwincloud.com"
    );

    const calendarLink = calendarRequest.generateLink();

    return (
        <>
            <Header />
            <div className="init-page-v1-pagecontainer">
                <div className="init-page-v1-row">
                    <div className="main-content">
                        <p className="normal-text">Hi, I'm Edwin Schleif</p>
                        <h1>I build <span className="highlight">Software</span></h1>
                        <p className="bigger-plus-1-text i-help-text">
                        I help companies build desktop, web-based, and mobile applications. I have worked for companies in the banking industry, media, electric car manufacturing, and other sectors.
                        </p>
                        {/* <div className='buttons-separation'>
                            <a className="works-button" href="/know-my-work">My Resume</a> 
                            <a className="works-button" href={calendarLink} target="_blank" rel="noopener noreferrer">Schedule a virtual meeting</a> 
                        </div> */}
                    </div>
                    <div className="sidebar">
                        <img src="/image/v1-face-only.png" alt="" />
                    </div>
                </div>
                <div className="init-page-v1-below-content">
                    <TypesOfSoftware />
                </div>
            </div>
            <Footer />
        </>
    );
};

export default InitPageV1;

export class TypesOfSoftwareContent {
  title: string;
  description: string;
  imageUrl: string; // Image URL instead of YouTube Video ID
  footerText: string;

  constructor(title: string, description: string, imageUrl: string, footerText: string) {
    this.title = title;
    this.description = description;
    this.imageUrl = imageUrl;
    this.footerText = footerText;
  }

  getTitle(): string {
    return this.title;
  }

  getDescription(): string {
    return this.description;
  }

  getImageUrl(): string {
    return this.imageUrl;
  }

  getFooterText(): string {
    return this.footerText;
  }
}

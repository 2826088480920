import React from 'react';
import './KnowMyWork.css';
import { KnowMyWorkContent } from '../models/KnowMyWorkContent';
import Header from '../PageShellComponents/Header';
import Footer from '../PageShellComponents/Footer';
import { TitleAndDescriptionContent } from '../models/TitleAndDescriptionContent';

const education: TitleAndDescriptionContent[] = [
    new TitleAndDescriptionContent("Computer Science - Bachelor - UGF, Rio de Janeiro, Brazil", ""),
    new TitleAndDescriptionContent("Microsoft Certification - Developing and Implementing Web Applications with Microsoft Visual C# .NET and Microsoft Visual Studio .NET", ""),
    new TitleAndDescriptionContent("Microsoft Certification - Designing and Implementing Databases with Microsoft SQL Server 2000 Enterprise Edition", "")
];

const workExperience: KnowMyWorkContent[] = [


    new KnowMyWorkContent(
        "Curtis Instruments, Livermore, California – July 2021 to Present - Staff Senior Software Engineer",
        "",
        "Primary responsibility:",
        "Secondary responsibility",
        "/image/v3-curtis.jpg",
        "",
        [
            "Led software engineering team in developing and maintaining Windows Desktop applications, significantly enhancing efficiency and capabilities of embedded engineers.",
            "Employed a diverse tech stack including WPF, Windows Forms, MVVM, DevExpress, Caliburn, SQL Server, Azure, AWS, Docker, and Jenkins for various projects.",
            "Led the implementation of DevOps practices, enhancing development workflows and project outcomes through continuous integration and delivery with Jenkins.",
            "Enhanced engineering processes with both cloud infrastructure and web tools development, supporting internal and external engineering needs."
        ]
    ),

    new KnowMyWorkContent("First Republic Bank, San Francisco Bay Area, California – October 2020 to June 2021 - Senior Software Engineer", "", "", ""
        , "/image/v3-first-republic.jpg", "",
        [
            "Managed maintenance and progressive enhancements of CRB, a CRM tool originally built in ASP.Net, transitioning to React and served with .NET WebAPI for enhanced banker-customer collaboration.",
            "Implemented feature upgrades and bug fixes within the ASP.Net framework 4.8 legacy system.",
            "Collaborated directly with end users and project managers to gather requirements and ensure alignment with business needs."
        ]),

    new KnowMyWorkContent("Jefferies, New York, New York – February 2019 to September 2020 - Senior Software Engineer",
        "", "", "", "/image/v3-jefferies.jpg", "",
        [
            "Led Prime Brokerage Technology application development for Hedge Fund clients, ensuring customized Asset Management solutions through direct collaboration, utilizing WPF, MVVM, Prism, and Design Patterns to create high-performance Windows applications.",
            "Coordinated system integrations with prominent financial entities such as Pershing, Northern Trust, enhancing data exchange and operational efficiency.",
            "Developed the Excel PNL System, incorporating real-time data from Bloomberg, ESignal, JETS, and Reuters to support advanced financial analysis.",
            "Engineered a WPF-based Windows Desktop System for live data feeds, supported by an order management system developed in Windows Forms and ASP.Net."
        ]),

    new KnowMyWorkContent("Mizuho Bank, Jersey City, NJ – 2016 to 2019 - Senior Software Engineer – Vice President",
        "", "", "", "/image/v2-mizuho.jpg", "",
        [
            "Oversaw maintenance and enhancement of bank's statement generation and distribution systems, providing SQL Server Development and SSIS database production support for proprietary applications.",
            "Utilized C# as the primary coding language, supplemented by VB.Net, Python, TypeScript, and JavaScript for specific functional requirements.",
            "Designed core components with .Net Windows Services and Console Applications, integrating Python scripts via Cisco Tidal Scheduler; developed web services with .Net Web API.",
            "Developed user interfaces using Angular and Bootstrap for responsive web design; employed WPF for Windows applications, adhering to the MVVM pattern. Prioritized Dependency Injection and rigorous Unit Testing for software integrity and optimal testability."
        ]),

    new KnowMyWorkContent("Pronology, New York, NY – 2014 to 2016 - Senior Software Engineer",
        "", "", "", "/image/v1-pronology.png", "",
        [
            "Directed the creation of time code-based user interface applications for managing media assets, prominently featured on pronology.com. Utilized C#, Windows Presentation Foundation (WPF), and SQL Server to engineer these solutions.",
            "Built core components using .Net Windows Services, Console Applications, and WCF Services, enabling diverse TV recording hardware integrations.",
            "Designed high-performance WPF UI applications, supporting prominent events like The Oscars and the 2015 Women’s World Cup, using multithreading and socket programming."
        ]),


    new KnowMyWorkContent(
        "Safra National Bank, New York, NY – 2007 to 2014 - Senior Software Engineer",
        "", "", "", "/image/v1-safra.jpg", "",
        [
            "Led development and maintenance of the bank's Stock and Options Trading System, delivering timely application enhancements and seamless integrations with financial platforms.",
            "Enhanced and supported a suite of applications, including IBanking, Middle/Back Office tools like Credit, Compliance, Stock Settlement, and monthly statement generation.",
            "Provided robust user support through various communication channels.",
            "Engaged in diverse coding tasks utilizing C#, VB.Net, VB 6, JScript, and VBScript.",
            "Crafted core functionalities using .Net Windows Services, Console Applications, screen scraper terminal applications, and web services, all underpinning the trading system.",
            "Designed UIs in ASP.Net, transitioning from Webforms to MVC, along with .Net Windows Forms and WPF."
        ]
    ),

    new KnowMyWorkContent(
        "BRQ Solutions – 2005 to 2007 - Senior Software Engineer",
        "", "", "", "", "", // Replace "/image/path.jpg" with the actual image path if available
        [
            "Delivered software solutions across finance and insurance sectors using ASP.Net and SQL Server."
        ]
    ),

    new KnowMyWorkContent(
        "Medcenter Solutions – 2003 to 2005 - Senior Software Engineer",
        "", "", "", "", "", // Replace "/image/path.jpg" with the actual image path if available
        [
            "Developed marketing systems and migrated applications from Classic ASP to ASP.Net Webforms."
        ]
    ),

    new KnowMyWorkContent(
        "Fundacao Roben Berta – 2002 to 2003 - Software Developer",
        "", "", "", "", "", // Replace "/image/path.jpg" with the actual image path if available
        [
            "Built the company intranet by migrating VB6 applications to Classic ASP and maintained legacy software."
        ]
    ),

    new KnowMyWorkContent(
        "Kzar Informatica – 2001 to 2002 - Junior Software Developer",
        "", "", "", "", "", // Replace "/image/path.jpg" with the actual image path if available
        [
            "Assisted in enhancing applications using Classic ASP, SQL Server, and Borland technologies."
        ]
    ),



];

const KnowMyWork: React.FC = () => {
    return (
        <>
            <Header />
            <div className="knowMyWork-container">
                <div className="knowMyWork-intro">
                    <a href="/document/v1-Edwin Schleif Resume.pdf">Download the PDF file</a>
                </div>

                <div className="knowMyWork-my-name-title">Edwin Schleif</div>
                <div className="knowMyWork-title-title">Software Engineer</div>

                <div className='knowMyWork-category-title bigger-plus-1-text'>Work Experience:</div>

                {workExperience.map((content, index) => (
                    <div key={index} className='knowMyWork-contentItem'>
                        <div className='kmw-1'>
                            {content.getImageUrl() && (
                                <img src={content.getImageUrl()} alt={content.getTitle()} className="workImage" />
                            )}
                            <p className='bigger-plus-1-text knowMyWork-item-title-parag'>{content.getTitle()}</p>
                        </div>

                        <div className="knowMyWork-contentBody">
                            <div className="knowMyWork-item-description">
                                {(content.getDescriptionList() || []).map((bullet, bulletIndex) => (
                                    <p key={bulletIndex} className='bigger-plus-01-text'>• {bullet}</p>
                                ))}
                                <p className='bigger-plus-01-text'>{content.getDescription()}</p>
                                <p className='bigger-plus-01-text'>{content.getFooterText()}</p>
                            </div>
                        </div>
                    </div>
                ))}



                <div className='knowMyWork-category-title bigger-plus-1-text'>Education:</div>

                {education.map((item, index) => (
                    <div key={index} className='knowMyWork-contentItem'>
                        <p className='bigger-plus-1-text'>{item.title}</p>
                    </div>
                ))}
            </div>
            <Footer />
        </>
    );
};

export default KnowMyWork;
